import { Worker, Viewer } from '@react-pdf-viewer/core';
import {
  defaultLayoutPlugin,
  ToolbarSlot,
} from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export const PDFViewer: React.FC<{
  uri: string;
  fileType: string;
  fileName?: string;
}> = ({ uri, fileType, fileName }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar) => (
      <Toolbar>
        {(slots: ToolbarSlot) => {
          const {
            CurrentPageInput,
            GoToNextPage,
            GoToPreviousPage,
            NumberOfPages,
            ShowSearchPopover,
            Zoom,
            ZoomIn,
            ZoomOut,
          } = slots;

          return (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                width: '100%',
              }}
            >
              <div style={{ padding: '0px 2px' }}>
                <ShowSearchPopover />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <ZoomOut />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <Zoom />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <ZoomIn />
              </div>
              <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                <GoToPreviousPage />
              </div>

              <div className=' sm:hidden' style={{ padding: '0px 2px' }}>
                <CurrentPageInput />
              </div>
              <div
                className='hidden sm:flex items-center'
                style={{ padding: '0px 2px' }}
              >
                <CurrentPageInput /> / <NumberOfPages />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <GoToNextPage />
              </div>
            </div>
          );
        }}
      </Toolbar>
    ),
  });

  return (
    <div className='w-full h-full rounded-lg 2xl:flex 2xl:flex-col'>
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
        <div className='h-[60vh] 2xl:h-[80vh]'>
          <Viewer fileUrl={uri} plugins={[defaultLayoutPluginInstance]} />
        </div>
      </Worker>
    </div>
  );
};
