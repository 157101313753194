import { TypeOf, object, string } from 'zod';

export const userRegistrationSchema = object({
  first_name: string().min(1, 'First name is required'),
  last_name: string().min(1, 'Last name is required'),
  email: string().email().min(1, 'Email is Required'),
  password: string().min(8, 'Password length must not be less than 8'),
  confirm_password: string(),
  // .min(
  //   8,
  //   'Confirm Password length must not be less than 8',
  // )
}).refine((data) => data.password === data.confirm_password, {
  message: "Passwords don't match",
  path: ['confirm_password'],
});

export type UserRegistrationInput = TypeOf<typeof userRegistrationSchema>;

export const loginSchema = object({
  email: string().email().min(1, 'Email is Required'),
  password: string().min(1, 'Password is required'),
});

export type LoginInput = TypeOf<typeof loginSchema>;

export type User = {
  first_name: string;
  last_name: string;
  //role: 'STUDENT' | 'ADMIN' | 'SUPERVISOR';
  email: string;
  phoneNumber: string;
  gender: 'M' | 'F';
  image: { presigned_url: string } | null;
};

export type LoginResponse = {
  status: 'success' | 'fail' | 'error';
  code: number;
  data?: {
    token: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  message?: string;
};
