import React from 'react';
import {
  Route,
  Routes,
  useRoutes,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';

import { AdminLayout, MainLayout, StudentLayout } from '../components/Layout';
import { About } from '../features/about';
import {
  Dashboard as AdminDashboard,
  Students,
  Courses as AdminCourses,
  LiveSessions as AdminLiveSessions,
  Staff,
  ScheduleLiveSession,
  EditLiveSession,
  ViewLiveSession,
} from '../features/admin';
import {
  Login,
  Register,
  VerifyEmail,
  ResetPassword,
  RegistrationSuccess,
  RequestPasswordReset,
  RequestVerificationEmailResend,
} from '../features/auth';
import { Contact } from '../features/contact';
import { Courses } from '../features/courses';
import { Landing } from '../features/landing';
import { LiveWorkshops, Registration } from '../features/liveworkshops';
import { NotFound, PrivacyPolicy, Terms } from '../features/misc';
import {
  MyCourses,
  Dashboard as StudentDashboard,
  Settings,
  CourseContent,
} from '../features/student';
import storage from '../utils/storage';

const ProtectedRoute: React.FC<{
  isAllowed: boolean;
  redirectPath?: string;
}> = ({ isAllowed, redirectPath = '/auth/login' }) => {
  const location = useLocation();

  if (!isAllowed) {
    return (
      <Navigate to={redirectPath} replace state={{ from: location.pathname }} />
    );
  }

  return <Outlet />;
};

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path='login' element={<Login />} />
      <Route path='register' element={<Register />} />
      <Route path='verify-email/:token' element={<VerifyEmail />} />
      <Route path='set-new-password' element={<ResetPassword />} />
      <Route path='registration-success' element={<RegistrationSuccess />} />
      <Route path='reset-password' element={<RequestPasswordReset />} />
      <Route
        path='resend-verification-email'
        element={<RequestVerificationEmailResend />}
      />
    </Routes>
  );
};

const WebsiteRoutes = () => {
  const token = storage.getToken();

  return (
    <MainLayout>
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='about-us' element={<About />} />
        <Route path='courses' element={<Courses />} />
        <Route path='contact' element={<Contact />} />
        <Route path='workshops' element={<LiveWorkshops />} />
        <Route element={<ProtectedRoute isAllowed={token} />}>
          <Route path='workshop-registration/:id' element={<Registration />} />
        </Route>
        <Route path='terms-of-service' element={<Terms />} />
        <Route path='privacy-policy' element={<PrivacyPolicy />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </MainLayout>
  );
};

const AdminRoutes = () => {
  const token = storage.getToken();
  const loggedInUser = storage.getUser();
  const isAuthenticated = !!token;

  if (!isAuthenticated) {
    return (
      <Navigate to='/auth/login' state={{ from: window.location.pathname }} />
    );
  }

  if (isAuthenticated && loggedInUser.role !== 'ADMIN') {
    return <Navigate to='/' />;
  }
  return (
    <AdminLayout>
      <Routes>
        <Route path='/' element={<AdminDashboard />} />
        <Route path='/students' element={<Students />} />
        <Route path='/courses' element={<AdminCourses />} />
        <Route path='/live-sessions' element={<AdminLiveSessions />} />
        <Route path='/live-sessions/edit/:id' element={<EditLiveSession />} />
        <Route path='/live-sessions/view/:id' element={<ViewLiveSession />} />
        <Route path='/staff' element={<Staff />} />
        <Route path='*' element={<NotFound />} />
        <Route
          path='/schedule-live-session'
          element={<ScheduleLiveSession />}
        />
      </Routes>
    </AdminLayout>
  );
};

const StudentRoutes = () => {
  const token = storage.getToken();
  const isAuthenticated = !!token;

  if (!isAuthenticated) {
    return (
      <Navigate to='/auth/login' state={{ from: window.location.pathname }} />
    );
  }

  return (
    <StudentLayout>
      <Routes>
        <Route path='/' element={<StudentDashboard />} />
        <Route path='my-courses' element={<MyCourses />} />
        <Route path='my-courses/:id' element={<CourseContent />} />
        <Route path='settings' element={<Settings />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </StudentLayout>
  );
};

export const AppRoutes = () => {
  const commonRoutes = [
    {
      path: '/*',
      element: <WebsiteRoutes />,
    },
    {
      path: '/auth/*',
      element: <AuthRoutes />,
    },
  ];

  const protectedRoutes =
    //['ADMIN', 'SUPERVISOR'].includes(loggedInUser?.role)
    //?
    [
      {
        path: '/admin/*',
        element: <AdminRoutes />,
      },
      //  ]
      //: [
      {
        path: '/student/*',
        element: <StudentRoutes />,
      },
    ];

  const routes =
    //token ?
    [...protectedRoutes, ...commonRoutes]; // : commonRoutes;

  const element = useRoutes(routes);

  return <>{element}</>;
};
