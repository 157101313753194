import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { axios } from '../../../../lib/axios';
import storage from '../../../../utils/storage';
import { getCourseDetails } from '../../../admin/livesessions/api/getLiveSession';
import { SectionProgress } from '../components/ResourcesList';

type CourseData = {
  course_id: string;
  course_title: string;
  sections: SectionProgress[];
};

type QueryData = {
  status: string;
  code: number;
  data: CourseData;
};

const getCourseProgress = async (courseID: string) => {
  const user = storage.getUser();
  const userCourses = user?.courses || [];

  if (userCourses.includes(courseID)) {
    const resp = await axios.get(
      `/sessions/course-resource-status/${courseID}`,
    );
    return resp.data;
  } else {
    //temp fix
    throw new Error('You do not have access to this course', {
      cause: 403,
    });
  }
};

const markResourceAsCompleted = async (resourceID: string) => {
  const resp = await axios.post(
    `/sessions/update-resource-progress/${resourceID}/`,
  );
  return resp.data;
};

export const useGetCourseProgress = (id: string) => {
  return useQuery({
    queryKey: [`course_${id}_progress`],
    queryFn: () => getCourseProgress(id),
    select: (data) => data.data,
    staleTime: 24 * 60 * 60 * 1000,
  });
};

export const useGetCourseDetails = (id: string) => {
  return useQuery({
    queryKey: [`course_${id}`],
    queryFn: () => getCourseDetails(id),
    select: (data) => data,
    staleTime: 24 * 60 * 60 * 1000,
  });
};

export const useMarkResourceAsCompleted = (
  courseID: string,
  sectionID: string,
  resourceID: string,
) => {
  const queryClient = useQueryClient();
  const oldQueryData = queryClient.getQueryData([
    `course_${courseID}_progress`,
  ]);

  return useMutation({
    mutationFn: markResourceAsCompleted,
    onSuccess: () => {
      const newQueryData = JSON.parse(
        JSON.stringify(oldQueryData),
      ) as QueryData;

      const section = newQueryData.data.sections.find(
        (sec) => sec.section_id === sectionID,
      );

      if (section) {
        const resource = section.resources.find(
          (res) => res.resource_id === resourceID,
        );

        if (resource) {
          resource.viewed = true;
        }
      }

      queryClient.setQueryData([`course_${courseID}_progress`], newQueryData);

      queryClient.invalidateQueries({
        queryKey: ['student-courses'],
      });
    },
  });
};
