/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import { documentIcon, videoIcon } from '../../../../assets';
import { ResourceDetails } from '../../types';

import { PDFViewer } from './PDFViewer';

export const Media: React.FC<{
  openResourceDetails: ResourceDetails;
}> = ({ openResourceDetails }) => {
  if (!openResourceDetails) {
    return <p>No content has been uploaded for this module yet.</p>;
  }

  const disableContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  return (
    <div
      className={`min-h-[200px] sm:min-h-[380px] relative flex w-full 2xl:min-h-[600px] ${!openResourceDetails.file_type.includes('pdf') ? '' : ''}`}
    >
      {openResourceDetails.file_type === 'video/mp4' ? (
        <div className='absolute inset-0 flex justify-center bg-grey rounded-lg'>
          <video
            className='w-full h-full rounded-lg'
            src={openResourceDetails.presigned_url}
            title={openResourceDetails.title}
            controls
            onContextMenu={disableContextMenu}
            controlsList='nodownload'
          ></video>
        </div>
      ) : openResourceDetails.file_type.includes('pdf') ? (
        <PDFViewer
          uri={openResourceDetails.presigned_url}
          fileName={openResourceDetails.title}
          fileType={openResourceDetails.file_type}
        />
      ) : openResourceDetails.file_type.includes('image') ? (
        <div className='absolute inset-0 flex justify-center bg-grey rounded-lg'>
          <img
            alt={openResourceDetails.title}
            src={openResourceDetails.presigned_url}
            className='w-full h-full rounded-lg'
            onContextMenu={disableContextMenu}
          />
        </div>
      ) : (
        <div>
          <h2 className='text-3xl'>Resources for this lecture</h2>
          <div className='flex mt-2 items-center'>
            <img
              className='h-4 w-4 mr-2'
              src={
                openResourceDetails.file_type === 'video/mp4'
                  ? videoIcon
                  : documentIcon
              }
              alt='icon'
            />{' '}
            <a
              href={openResourceDetails.presigned_url}
              target='blank'
              className='link text-blue-600'
            >
              {openResourceDetails.title}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
