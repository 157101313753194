import { MediaType } from '../features/admin/livesessions/types';
import { SectionProgress } from '../features/student/mycourses/components/ResourcesList';
import {
  CourseDetailsProps,
  CourseProgressProps,
  StudentCoursesDataProps,
} from '../features/student/types';

export const getBarColor = (progress: number) => {
  if (!progress || (progress > 0 && progress <= 40)) {
    return 'red';
  } else if (progress > 40 && progress <= 70) {
    return '#F8BD00';
  } else {
    return 'green';
  }
};

export const getProgressPercentage = (
  completedResourceCount: number,
  totalResourceCount: number,
) => {
  return Math.round((completedResourceCount / totalResourceCount) * 100);
};

export const getCourseByProgessStatus = (
  status: 'IN PROGRESS' | 'COMPLETED',
  data: StudentCoursesDataProps,
): StudentCoursesDataProps => {
  let filteredProgress: CourseProgressProps[] = [];
  let filteredCourses: CourseDetailsProps[] = [];

  switch (status) {
    case 'IN PROGRESS':
      filteredProgress = data.progress_details.filter((course) => {
        const progress = getProgressPercentage(
          course.completed_resources,
          course.total_resources,
        );

        return progress > 0 && progress < 100;
      });

      filteredCourses = data.course_details.filter((course) =>
        filteredProgress.some((progress) => progress.course_id === course.id),
      );
      break;

    case 'COMPLETED':
      filteredProgress = data.progress_details.filter((course) => {
        const progress = getProgressPercentage(
          course.completed_resources,
          course.total_resources,
        );

        return progress === 100;
      });

      filteredCourses = data.course_details.filter((course) =>
        filteredProgress.some((progress) => progress.course_id === course.id),
      );
      break;

    default:
      filteredProgress = data.progress_details;
      filteredCourses = data.course_details;
      break;
  }

  return {
    ...data,
    progress_details: filteredProgress,
    course_details: filteredCourses,
  };
};

export const getMediaType = (file: File) => {
  const validMediaTypes: MediaType[] = [
    'video/mp4',
    'video/quicktime',
    'video/x-msvideo',
    'video/x-matroska',
    'video/x-ms-wmv',
    'video/webm',
    'audio/mpeg',
    'audio/wav',
    'audio/ogg',
    'audio/mp4',
    'audio/webm',
    'audio/x-aac',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/zip',
    'application/x-rar-compressed',
    'application/x-tar',
    'application/x-7z-compressed',
    'application/gzip',
    'application/x-bzip2',
    'text/plain',
    'text/csv',
    'text/html',
    'text/xml',
    'text/css',
    'text/javascript',
    'application/json',
    'application/xml',
    'application/rtf',
    'application/x-sh',
    'application/x-www-form-urlencoded',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
    'image/png',
    'image/jpg',
    'image/jpeg',
  ];

  if (validMediaTypes.includes(file.type as MediaType)) {
    return file.type as MediaType;
  }

  return null;
};

export const findFirstUnviewedResource = (courseProgress: {
  course_id: string;
  course_title: string;
  sections: SectionProgress[];
}) => {
  const sectionIndex = courseProgress.sections.findIndex((section) =>
    section.resources.some((resource) => !resource.viewed),
  );

  if (sectionIndex !== -1) {
    const resourceIndex = courseProgress.sections[
      sectionIndex
    ].resources.findIndex((resource) => !resource.viewed);
    return { sectionIndex, resourceIndex };
  }

  return { sectionIndex: null, resourceIndex: null };
};
