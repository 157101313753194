import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { CheckIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

import { documentIcon, videoIcon } from '../../../../assets';
import { Resource, Section } from '../../../admin/livesessions/types';

import { getTabStyle } from './Details';

type ResourceProgress = {
  resource_id: string;
  title: string;
  viewed: boolean;
};

export type SectionProgress = {
  section_id: string;
  section_title: string;
  resources: ResourceProgress[];
};

export type ResourcesProps = {
  sectionsDetails: Section[];
  sectionsProgress: SectionProgress[];
  openSectionIndex: number | null;
  openResourceIndex: number | null;
  currentSectionIndex: number | null;
  setOpenSectionIndex: (idx: number | null) => void;
  setOpenResourceIndex: (idx: number | null) => void;
  setCurrentSectionIndex: (idx: number | null) => void;
};

export const ResourcesList = ({
  sectionsDetails,
  openSectionIndex,
  sectionsProgress,
  setOpenSectionIndex,
  openResourceIndex,
  setOpenResourceIndex,
  setCurrentSectionIndex,
  currentSectionIndex,
}: ResourcesProps) => {
  const handleToggle = (index: number) => {
    setOpenSectionIndex(openSectionIndex === index ? null : index);
  };

  return (
    <div className='xl:ml-4 mt-4 xl:mt-0'>
      <TabGroup className=''>
        <TabList className='flex p-1 border-slate-300 border-b-[0px]'>
          <Tab
            key='Module'
            className={({ selected }) => {
              return getTabStyle(selected);
            }}
          >
            MODULES
          </Tab>
          {/* <Tab
            key='Notes'
            className={({ selected }) => {
              return getTabStyle(selected);
            }}
          >
            Notes
          </Tab> */}
        </TabList>
        <TabPanels className='mt-2'>
          <TabPanel key='Module' className={``}>
            <div className=''>
              {sectionsProgress.map(
                (sectionProgress: SectionProgress, index: number) => {
                  const sectionDetail = sectionsDetails.find(
                    (sectionDetail) =>
                      sectionDetail.id === sectionProgress.section_id,
                  );

                  const numOfFiles = sectionDetail?.resources.filter(
                    (resource: Resource) => resource.file_type !== 'video/mp4', //=== 'application/pdf',
                  ).length;

                  const numOfVideos = sectionDetail?.resources.filter(
                    (resource: Resource) => resource.file_type === 'video/mp4',
                  ).length;

                  return (
                    <>
                      <button
                        onClick={() => handleToggle(index)}
                        key={index}
                        className={`${index === openSectionIndex ? 'bg-gray-100' : 'border-[1px] border-gray-300 mb-2'}  flex flex-col w-full px-4 py-2 text-sm font-medium text-left text-gray-900 hover:bg-gray-200 rounded-lg  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}
                      >
                        <div className='flex justify-between w-full'>
                          <span>{sectionProgress.section_title}</span>
                          <ChevronUpIcon
                            className={`${
                              openSectionIndex === index
                                ? 'transform rotate-180'
                                : ''
                            } w-5 h-5 text-gray-500`}
                          />
                        </div>
                        <div className='text-xs mt-2 text-gray-500'>
                          {numOfVideos
                            ? `${numOfVideos} ${numOfVideos > 1 ? 'videos' : 'video'}`
                            : null}{' '}
                          {numOfFiles
                            ? `${numOfFiles} ${numOfFiles > 1 ? 'files' : 'file'}`
                            : null}
                          {/* 5 videos, 3 files */}
                        </div>
                      </button>
                      {openSectionIndex === index && (
                        <div className='px-4  pb-2 text-sm text-gray-500'>
                          <ul>
                            {sectionProgress.resources.map(
                              (resource: ResourceProgress, idx: number) => {
                                const resourceDetail =
                                  sectionDetail?.resources.find(
                                    (resourceD) =>
                                      resourceD.id === resource.resource_id,
                                  );
                                return (
                                  <li
                                    key={idx}
                                    className={`flex flex-col py-1`}
                                  >
                                    <div className='flex items-center'>
                                      <div className='w-8'>
                                        <div
                                          className={`${resource.viewed ? 'bg-[#007AFF]  flex items-center justify-center' : 'bg-white'} h-5 w-5 rounded-full border-[1px] border-blue-400 `}
                                        >
                                          {resource.viewed && (
                                            <CheckIcon className='h-[14px] text-white' />
                                          )}
                                        </div>
                                      </div>

                                      <div className='flex flex-col text-black'>
                                        <button
                                          onClick={() => {
                                            setOpenResourceIndex(idx);
                                            setCurrentSectionIndex(index);
                                          }}
                                          className={`${openResourceIndex === idx && currentSectionIndex === index ? 'text-blue-500' : ''} flex items-center`}
                                        >
                                          <img
                                            className='h-4 w-4 mr-2'
                                            src={
                                              resourceDetail?.file_type ===
                                              'video/mp4'
                                                ? videoIcon
                                                : documentIcon
                                            }
                                            alt='icon'
                                          />
                                          <span className='md:hidden lg:block 2xl:hidden'>
                                            {`${idx < 9 ? '0' : ''}${idx + 1}`}{' '}
                                            -{' '}
                                            {String(resource.title).substring(
                                              0,
                                              26,
                                            )}
                                            {resource.title.length > 26
                                              ? '...'
                                              : ''}
                                          </span>
                                          <span className='hidden md:block lg:hidden 2xl:block'>
                                            {resource.title}
                                          </span>
                                        </button>
                                        {/* <span className='text-gray-400 text-xs'>
                                          ({video.duration})
                                        </span> */}
                                      </div>
                                    </div>
                                  </li>
                                );
                              },
                            )}
                          </ul>
                        </div>
                      )}
                    </>
                  );
                },
              )}
            </div>
          </TabPanel>
          <TabPanel key='Note' className={``}>
            Notes...
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
};
