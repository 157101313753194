import { Head } from '../../../components/Head';
import { UpcomingSessions } from '../../liveworkshops/components/UpcomingSessions';
import {
  Hero,
  Offer,
  Testimonials,
  WhyTakeOurCourses,
  YoutubeChannel,
} from '../components';

export const Landing = () => {
  console.log('Welcome to AwesumEdge');

  return (
    <>
      <Head title='Home' />
      <div className=''>
        <Hero />
        <Offer />
        <UpcomingSessions bgColor='#F4F4F4' />
        <WhyTakeOurCourses />
        <YoutubeChannel />
        <Testimonials />
      </div>
    </>
  );
};
