import { Box, Grid, Modal } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { correc } from '../../../assets';

export const RegistrationSuccess: React.FC<{
  isOpen: boolean;
  courseID?: string;
}> = ({ isOpen, courseID }) => {
  const navigate = useNavigate();

  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div
          className='reg-success'
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 550,
            minHeight: 350,
            backgroundColor: 'white',
            padding: '20px',
            color: 'rgba(13, 6, 95, 1)',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '10px',
          }}
        >
          <Grid container direction='column' alignItems='center' spacing={2}>
            <Grid item>
              <img
                width={'60px'}
                src={correc}
                alt='successfulRegisterlogo'
                style={{ maxWidth: '100%' }}
              />
            </Grid>
            <Grid item>
              <Box
                fontSize={{ lg: '26px', xs: '20px', md: '26px', sm: '26px' }}
              >
                <h2
                  id='modal-modal-title'
                  style={{
                    fontWeight: 'bolder',
                    borderBottom: '1px solid #ccc',
                    paddingBlock: '15px',
                  }}
                >
                  Registration Completed Successfully
                </h2>
              </Box>
            </Grid>

            <Grid item>
              <Box
                fontSize={{ lg: '16px', xs: '12px', md: '14px', sm: '14px' }}
              >
                <p
                  style={{
                    paddingLeft: '15px',
                    paddingRight: '15px',
                  }}
                  id='modal-modal-description'
                >
                  Your registration process is completed and You can now access
                  the course.
                </p>
              </Box>
            </Grid>
            <Grid item>
              <button
                onClick={() => navigate(`/student/my-courses/${courseID}`)}
                type='button'
                className='flex items-center justify-center bg-primary hover:bg-secondary text-sm h-[40px] w-[220px] p-2 rounded-md text-white shadow-all-sides'
              >
                Go to Course
              </button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};
